<div class="game-container">
  <app-field [game]="game$"></app-field>
  <app-hud [playerHandCards]="playerCards" [playerName]="player" [currentGame]="currentGame"></app-hud>
</div>

<app-modal [show]="showPredictionModal" [title]="'Predict Your Victories'" (close)="closePredictionModal()">
  <div class="prediction-content">
    <p>How many victories do you predict?</p>
    <div class="prediction-button-group">
      <button 
        *ngFor="let number of predictionButtons" 
        (click)="onPredict(number)"
        class="prediction-button"
      >
        {{ number }}
      </button>
    </div>
  </div>
</app-modal>