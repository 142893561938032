import { Component, Input, OnInit } from '@angular/core';
import { Card, CardSign, Game } from '../../game/game-models';
import { Observable } from 'rxjs';// Adjust the import path as needed

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrl: './field.component.css'
})
export class FieldComponent implements OnInit {
  @Input('game') game$!: Observable<Game>;
  game: Game | undefined;

  ngOnInit(): void {
    this.game$.subscribe(game => { 
      if (game) {
        this.game = game;
        console.log("FieldComponent", this.game);
      }
    });
  }

  getPlayerPosition(index: number): string {
    const positions = ['top-left', 'top-center', 'top-right', 'right',
                       'bottom-right', 'bottom-center', 'bottom-left', 'left'];
    return positions[index] || '';
  }

  getGlowClass(card: Card | undefined | null): string {
    if (!card) return '';

    switch (card.sign) {
      case CardSign.Fire:
        return 'glow-F';
      case CardSign.Water:
        return 'glow-W';
      case CardSign.Air:
        return 'glow-A';
      case CardSign.Earth:
        return 'glow-E';
      default:
        return '';
    }
  }
}
