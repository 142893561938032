<div class="home-container" *ngIf="!showGameView">
  <div class="content">
    <h1>Welcome to Buriza Online</h1>
    <div class="input-group">
      <input type="text" placeholder="Enter your nickname" [(ngModel)]="playerName">
    </div>
    <button (click)="joinGame()">Join Game</button>
  </div>
</div>

<div class="container" *ngIf="showGameView && (game$ | async)?.isStarted">
    <ng-container *ngIf="isMobile; else desktopView">
      <app-mobile></app-mobile>
    </ng-container>
    <ng-template #desktopView>
      <app-desktop></app-desktop>
    </ng-template>
</div>  

<div class="container" *ngIf="showGameView && !showGameEndModal">
  <button (click)="startGame()">Start</button>
</div> 

<app-modal [show]="showGameEndModal" [title]="'Game Over'" (close)="showGameEndModal = false">
  <div class="game-end-content">
    <h3>Final Scores</h3>
    <div *ngFor="let player of currentGame?.players | keyvalue" class="player-score">
      <span>{{ player.key }}:</span>
      <span>{{ player.value.score }}</span>
    </div>
    <button (click)="goToHomePage()">Ok</button>
  </div>
</app-modal>
