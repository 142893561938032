import { Component, OnInit } from '@angular/core';
import { Card, Game, Prediction } from '../game/game-models';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCurrentGame } from '../game/game.reducer';
import * as GameActions from '../game/game.actions';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrl: './mobile.component.css'
})
export class MobileComponent implements OnInit {
  game$: Observable<Game>;
  playerCards: Card[] = [];
  player: string;
  showPredictionModal: boolean = false;
  prediction: number = 0;
  currentGame: Game | null = null;

  constructor(private store: Store<Game>) {
    this.player = localStorage.getItem('username') || '';
    this.game$ = this.store.select(selectCurrentGame) as Observable<Game>;
  }

  ngOnInit() {
    this.game$.subscribe(game => {
      console.log("Mobile", game);
      this.currentGame = game;
      this.playerCards = game.players[this.player]?.cards || [];
      
      // Check if it's the prediction phase and if it's the current player's turn
      this.showPredictionModal = game.isPredictingPhase && 
                                 game.predictingOrder[0] === this.player;

    });
  }

  closePredictionModal() {
    this.showPredictionModal = false;
    this.prediction = 0;
  }

  get predictionButtons(): number[] {
    if (this.currentGame!.round! < 7) {
      return this.currentGame ? Array.from({ length: this.currentGame.round + 1 }, (_, i) => i) : [];
    }
    else {
      return this.currentGame ? Array.from({ length: 14 - this.currentGame.round }, (_, i) => i) : [];
    }
  }

  onPredict(prediction: number) {
    if (this.currentGame) {
      const predictionData: Prediction = {
        gameName: this.currentGame.name,
        playerName: this.player,
        numberOfWins: prediction
      };
      this.store.dispatch(GameActions.predict({ prediction: predictionData }));
      this.closePredictionModal();
    } else {
      console.log('Game data not available');
    }
  }
  
}
