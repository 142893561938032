import { Pipe, PipeTransform } from '@angular/core';
import { Card, CardSign } from '../components/game/game-models';

@Pipe({
  name: 'cardAltText'
})
export class CardAltTextPipe implements PipeTransform {
  transform(card: Card | null | undefined): string {
    if (!card) {
      return 'Card back';
    }
    return `${card.value} of ${CardSign[card.sign]}`;
  }
}