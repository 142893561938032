import { createReducer, createSelector, createFeatureSelector, on } from '@ngrx/store';
import * as GameActions from './game.actions';
import { Game } from './game-models';

export interface GameState {
  currentGame: Game | null;
  error: any;
}

export const initialState: GameState = {
  currentGame: null,
  error: null
};

export const gameReducer = createReducer(
  initialState,
  on(GameActions.createGameSuccess, (state, { game }) => {
    console.log('Reducer: createGameSuccess', game);
    return {
      ...state,
      currentGame: game,
      error: null
    };
  }),
  on(GameActions.createGameFailure, (state, { error }) => {
    console.log('Reducer: createGameFailure', error);
    return {
      ...state,
      error: error
    };
  }),
  on(GameActions.joinGameSuccess, (state, { game }) => {
    console.log('Reducer: joinGameSuccess', game);
    return {
      ...state,
      currentGame: game,
      error: null
    };
  }),
  on(GameActions.joinGameFailure, (state, { error }) => {
    console.log('Reducer: joinGameFailure', error);
    return {
      ...state,
      error: error
    };
  }),
  on(GameActions.updateGameState, (state, { game }) => ({
    ...state,
    currentGame: game
  })),
  on(GameActions.predictSuccess, (state) => {
    console.log('Reducer: predictSuccess');
    return {
      ...state,
      error: null
    };
  }),
  on(GameActions.predictFailure, (state, { error }) => {
    console.log('Reducer: predictFailure', error);
    return {
      ...state,
      error: error
    };
  })
);

export const selectGameState = (state: any) => state.game;

export const selectCurrentGame = createSelector(
    selectGameState,
    (state: GameState) => state.currentGame
  );
