import { CommonModule } from '@angular/common';
import { Component, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Card, CardSign, Game, Move, Player } from '../../game/game-models'; // Adjust the import path as needed
import * as GameActions from '../../game/game.actions';

@Component({
  selector: 'app-hud',
  templateUrl: './hud.component.html',
  styleUrl: './hud.component.css'
})
export class HudComponent {
  @Input() playerHandCards: Card[] = [];
  @Input() playerName: string | null = null;
  @Input() currentGame: Game | null = null;

  private deferredPrompt: any;

  constructor(private store: Store) {}

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(e: Event) {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later
    this.deferredPrompt = e;
  }

  async installPwa() {
    if (this.deferredPrompt) {
      // Show the install prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await this.deferredPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, discard it
      this.deferredPrompt = null;
    } else {
      console.log('PWA installation is not available');
      // Optionally, you can show a message to the user explaining how to install manually
      alert('To install the app, use your browser\'s "Add to Home Screen" option.');
    }
  }

  onCardClick(card: Card) {
    const move: Move = {
      gameName: this.currentGame!.name,
      playerName: this.playerName!,
      card: card
    };
    console.log(move);
    this.store.dispatch(GameActions.move({ move }));
  }
}
