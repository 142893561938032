import { Component, Input } from '@angular/core';
import { Player, Card } from '../../game/game-models';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrl: './player.component.css'
})
export class PlayerComponent {
  @Input() player!: Player;

  get lastPlayedCard(): Card | null {
    return this.player.playedCards && this.player.playedCards.length > 0
      ? this.player.playedCards[this.player.playedCards.length - 1]
      : null;
  }
}
