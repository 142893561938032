<button (click)="installPwa()" class="install-button">Install App</button>
<div class="home-container">
  <div class="content">
    <h1>Welcome to Buriza Online</h1>
    <div class="input-group">
      <input type="text" placeholder="Enter your nickname" [(ngModel)]="userNickname">
    </div>
    <button (click)="createGame()">Create Game</button>
    <br><br>
  </div>
</div>
