<div class="top-section">
  <div class="grid-container">
    <!-- Table card (always displayed) -->
    <div class="grid-item table-card">
      <img 
        [src]="game?.tableCard | cardImageUrl" 
        [attr.alt]="game?.tableCard | cardAltText" 
        class="table-card-image"
        [ngClass]="getGlowClass(game?.tableCard)">
    </div>

    <!-- Players -->
    <ng-container *ngFor="let player of game?.players | keyvalue; let i = index">
      <div class="grid-item player" [ngClass]="getPlayerPosition(i)">
        <app-player [player]="player.value"></app-player>
      </div>
    </ng-container>
  </div>
</div>
