<div class="player-wrapper">
  <div class="player-name">{{player.name}}</div>
  <div class="player-container">
    <div class="player-card">
      <img 
        [src]="lastPlayedCard | cardImageUrl" 
        [attr.alt]="lastPlayedCard | cardAltText" 
        class="card-image">
    </div>
    <div class="player-stats">
      <div class="predictions">{{player.predictedVictories}}</div>
      <div class="victories">{{player.actualVictories}}</div>
    </div>
  </div>
</div>
