import { createAction, props } from '@ngrx/store';
import { Game, Prediction, Card, Move } from './game-models';

export const createGame = createAction(
  '[Game] Create Game',
  props<{ playerName: string }>()
);
export const createGameSuccess = createAction(
  '[Game] Create Game Success',
  props<{ game: Game }>()
);
export const createGameFailure = createAction(
  '[Game] Create Game Failure',
  props<{ error: any }>()
);

export const joinGame = createAction(
  '[Game] Join Game',
  props<{ gameName: string; playerName: string }>()
);

export const joinGameSuccess = createAction(
  '[Game] Join Game Success',
  props<{ game: Game }>()
);

export const joinGameFailure = createAction(
  '[Game] Join Game Failure',
  props<{ error: any }>()
);

export const updateGameState = createAction(
  '[Game] Update Game State',
  props<{ game: Game }>()
);

export const predict = createAction(
  '[Game] Predict',
  props<{ prediction: Prediction }>()
);

export const predictSuccess = createAction(
  '[Game] Predict Success'
);

export const predictFailure = createAction(
  '[Game] Predict Failure',
  props<{ error: any }>()
);

export const move = createAction(
  '[Game] Play Card',
  props<{ move: Move }>()
);

export const moveSuccess = createAction(
  '[Game] Play Card Success',
);

export const moveFailure = createAction(
  '[Game] Play Card Failure',
  props<{ error: string }>()
);
