<div class="hud-container">
  <div class="top-section">
    <!-- Add your control buttons or other content for the top section here -->
    <button (click)="installPwa()">Options</button>
    <button>Score</button>
    <button>Graveyard</button>
  </div>
  <div class="bottom-section">
    <div class="player-cards" #playerCards>
      <div class="card" *ngFor="let card of playerHandCards" (click)="onCardClick(card)">
        <img 
          [src]="card | cardImageUrl" 
          [alt]="card | cardAltText">
      </div>
    </div>
  </div>
</div>
