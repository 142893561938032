import { ActionReducer, MetaReducer } from '@ngrx/store';

export function localStorageMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    console.log('Meta-reducer: Action type:', action.type);

    // Rehydrate state from localStorage
    const savedState = localStorage.getItem('game');
    if (savedState) {
      state = { ...state, ...JSON.parse(savedState) };
    }

    // Pass the state to the reducer
    const nextState = reducer(state, action);

    // Persist the new state to localStorage
    localStorage.setItem('game', JSON.stringify(nextState));

    console.log('Meta-reducer: New state:', nextState);

    return nextState;
  };
}
