import { Pipe, PipeTransform } from '@angular/core';
import { Card, CardSign } from '../components/game/game-models';

@Pipe({
  name: 'cardImageUrl'
})
export class CardImageUrlPipe implements PipeTransform {
  transform(card: Card | null | undefined): string {
    if (!card) {
      return 'assets/cards/en/X_X.svg';
    }
    return `assets/cards/en/${this.getCardFileName(card)}.svg`;
  }

  private getCardFileName(card: Card): string {
    return `${card.value}_${CardSign[card.sign][0].toUpperCase()}`;
  }
}